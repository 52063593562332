<template>
  <div>
    <div class="hq_top">
      <div class="wrap">
        <a class="pointer" @click="logo('/origin/consult_schedule')">
          <img class="logo2" src="@/assets/images/logo.png" />
        </a>
        <ul class="gnb">
          <li class="hov-menu" v-if="isMaster">
            <a class="pointer" style="text-decoration: underline;" href="http://old.happynetwork.info/login" target="_blank">구 CRM 바로가기</a>
          </li>
          <li>
            <a class="pointer" @click="movePage('/notice')" :class="boldMenu('/notice')">공지사항</a>
          </li>
          <li class="hov-menu" v-if="isMaster">
            <a class="pointer" :class="boldMenu('/admin')" @click="moveAdmin('/admin')">관리자</a>
          </li>

          <li class="hov-menu">
            <a class="pointer" :class="boldMenu('/consult_')" @click="movePage('/consult_schedule')">상담관리</a>
            <div>
              <!-- origin일 경우 -->
              <ul class="submenu-consult" v-if="isMaster || isAdmin">
                <li>
                  <a><strong class="cp txt-black" @click="movePage('/consult_schedule')">상담일정</strong></a>
                </li>
                <li>
                  <a><strong class="cp txt-black" @click="movePage('/consult_reserve')">상담예약관리</strong></a>
                </li>
                <li>
                  <a><strong class="cp txt-black" @click="movePage('/customer-service')">전화문의관리</strong></a>
                </li>
              </ul>

              <!-- 상담사일경우 -->
              <ul class="submenu-counselor" v-else>
                <li>
                  <a><strong class="cp txt-black" @click="movePage('/consult_schedule')">상담일정</strong></a>
                </li>
                <li>
                  <a><strong class="cp txt-black" @click="movePage('/consult_reserve')">상담예약관리</strong></a>
                </li>
              </ul>
            </div>
          </li>

          <li class="hov-menu" v-if="isCenterdirector">
            <a class="pointer" :class="boldMenu('/center_manage_modify')" @click="movePage(`/center_manage_modify?idx=${centerIdx}`)">센터정보수정</a>
          </li>

          <!--  본원센터일 경우 -->
          <li class="hov-menu" v-if="isMaster || isAdmin">
            <a class="pointer" :class="boldMenu('/person_')" @click="movePage(isMaster || isAdmin ? '/person_manage' : '/customer_manage')">고객관리</a>
            <div>
              <ul class="submenu-customer">
                <li v-if="isMaster || isAdmin">
                  <a><strong class="cp txt-black" @click="movePage('/person_manage')">내담자관리</strong></a>
                </li>
                <li>
                  <a><strong class="cp txt-black" @click="movePage('/customer_manage')">고객차트관리</strong></a>
                </li>
                <li v-if="isMaster || isAdmin">
                  <a><strong class="cp txt-black" @click="movePage('/customer_acceptance_b2c')">B2C수납관리</strong></a>
                </li>
                <li v-if="isMaster || isAdmin">
                  <a><strong class="cp txt-black" @click="movePage('/customer_acceptance_b2b')">B2B수납관리</strong></a>
                </li>
              </ul>
            </div>
          </li>

          <!-- 상담사일 경우  -->
          <li class="hov-menu" v-else>
            <a class="pointer" :class="boldMenu('/person_')" @click="movePage(isMaster || isAdmin ? '/person_manage' : '/customer_manage')">고객관리</a>
            <div>
              <ul class="submenu-customer-counselor">
                <li>
                  <a><strong class="cp txt-black" @click="movePage('/customer_manage')">고객차트관리</strong></a>
                </li>
              </ul>
            </div>
          </li>

          <!--  본원센터일 경우 -->
          <li class="hov-menu" v-if="isMaster || isAdmin">
            <a class="pointer" @click="movePage('/counselor_manage')" :class="boldMenu('/counselor_')">상담사관리</a>
            <div>
              <ul class="submenu-teacher">
                <li>
                  <a><strong class="cp txt-black" @click="movePage('/counselor_manage')">상담사관리</strong></a>
                </li>
                <li>
                  <a><strong class="cp txt-black" @click="movePage('/counselor_journey')">상담일지관리</strong></a>
                </li>
                <li v-if="isCenterdirector" @click="movePage('/confirm_counselor')">
                  <a><strong class="cp txt-black">상담사 승인</strong></a>
                </li>
              </ul>
            </div>
          </li>

          <li class="hov-menu" v-else>
            <a class="pointer" @click="movePage('/counselor_manage')" :class="boldMenu('/counselor_')">상담사관리</a>
            <div>
              <ul class="submenu-teacher-counselor">
                <li @click="movePage('/confirm_counselor')">
                  <a><strong class="cp txt-black">상담사 승인</strong></a>
                </li>
              </ul>
            </div>
          </li>

          <li class="hov-menu" v-if="isMaster || isAdmin">
            <a class="pointer" @click="movePage('/center_manage')" :class="boldMenu('/center_')">센터관리</a>
          </li>

          <li class="hov-menu" v-if="isMaster || isAdmin">
            <a class="pointer" @click="movePage('/company_manage')" :class="boldMenu('/company_')">기업관리</a>
            <div>
              <ul class="submenu-company">
                <li>
                  <a><strong class="cp txt-black" @click="movePage('/company_manage')">기업목록</strong></a>
                </li>
                <li>
                  <a><strong class="cp txt-black" @click="movePage('/company_manage_register')">기업등록</strong></a>
                </li>
              </ul>
            </div>
          </li>

          <li class="hov-menu" v-if="isMaster || isAdmin">
            <a class="pointer" @click="movePage('/statistic_consult')" :class="boldMenu('/statistic_')">통계관리</a>
            <div>
              <ul class="submenu-statistics">
                <li>
                  <a><strong class="cp txt-black" @click="movePage('/statistic_consult')">상담통계</strong></a>
                </li>
                <li>
                  <a><strong class="cp txt-black" @click="movePage('/statistic_company')">기업별 통계</strong></a>
                </li>
                <li>
                  <a><strong class="cp txt-black" @click="movePage('/statistic_salary')">급여 통계</strong></a>
                </li>
              </ul>
            </div>
          </li>

          <li class="hov-menu" v-if="isMaster || isAdmin">
            <a class="pointer" @click="movePage('/sales_manage_b2c')" :class="boldMenu('/sales_')">매출관리</a>
            <div>
              <ul>
                <li>
                  <a><strong class="cp txt-black" @click="movePage('/sales_manage_b2c')">B2C매출내역</strong></a>
                </li>
                <li>
                  <a><strong class="cp txt-black" @click="movePage('/sales_manage_b2b')">B2B매출내역</strong></a>
                </li>
                <li>
                  <a><strong class="cp txt-black" @click="movePage('/sales_manage_expend_b2c')">B2C지출관리</strong></a>
                </li>
                <li>
                  <a><strong class="cp txt-black" @click="movePage('/sales_manage_expend_b2b')">B2B지출관리</strong></a>
                </li>
              </ul>
            </div>
          </li>

          <!-- 본원 센터일 경우 -->
          <li class="hov-menu" v-if="isMaster || isAdmin">
            <a class="pointer" @click="movePage(isMaster || isAdmin ? '/salary_manage' : '/salary_convention')" :class="boldMenu('/salary_')">급여관리</a>
            <div>
              <ul class="submenu-salary">
                <li>
                  <a><strong class="cp txt-black" v-if="isMaster || isAdmin" @click="movePage('/salary_manage')">상담사급여내역</strong></a>
                </li>
                <li>
                  <a><strong class="cp txt-black" @click="movePage('/salary_convention')">협약센터급여내역</strong></a>
                </li>
              </ul>
            </div>
          </li>

          <!-- 상담사일 경우 -->
          <li class="hov-menu" v-else>
            <a class="pointer" @click="movePage(isMaster || isAdmin ? '/salary_manage' : '/salary_convention')" :class="boldMenu('/salary_')">급여관리</a>
            <div>
              <ul class="submenu-salary-counselor">
                <li>
                  <a><strong class="cp txt-black" @click="movePage('/salary_convention')">협약센터급여내역</strong></a>
                </li>
              </ul>
            </div>
          </li>

          <li class="hov-menu">
            <a class="pointer" @click="logout()">로그아웃</a>
          </li>
        </ul>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    toPath: "",
    isMaster: false,
    isAdmin: false,
    isCenterdirector: false,
    centerIdx: localStorage.getItem("idxCrmCenter"),
  }),

  computed: {
    ...mapGetters(["getUserInfo"]),
  },

  watch: {
    // 관리자 페이지 내의 현재 라우터 경로 감지
    // Watch에 넣어놓은 이유는 keep-alive 사용으로 페이지 진입후 재진입할때 mount가 안되기 때문
    $route(to) {
      this.toPath = to.fullPath;
    },
  },

  mounted() {
    if (localStorage.getItem("auth") === "MASTER") {
      this.isMaster = true;
    } else {
      this.isMaster = false;
    }

    if (localStorage.getItem("auth") === "ADMIN") {
      this.isAdmin = true;
    } else {
      this.isAdmin = false;
    }

    if (localStorage.getItem("auth") === "CENTERDIRECTOR") {
      this.isCenterdirector = true;
    } else {
      this.isCenterdirector = false;
    }

    this.toPath = this.$route.fullPath;
  },

  methods: {
    // 모든 페이지에서 로고 클릭시 상담일정 화면으로 가도록 20210316
    logo(path) {
      sessionStorage.clear();
      if (location.href.indexOf(path) > -1) {
        this.$router.go(this.$router.currentRoute);
      } else {
        this.$router.push(path).catch(() => {});
      }
    },

    moveAdmin(path) {
      sessionStorage.clear(); // 검색유지조건 삭제 20210128
      this.$router.push(path).catch(() => {});
    },
    // 현재 진입한 페이지 bold처리
    boldMenu(path) {
      if (this.toPath.includes(path)) {
        return "bold";
      } else {
        return "";
      }
    },

    // 각 입력된 path 페이지로 이동
    movePage(path) {
      sessionStorage.clear(); // 검색유지조건 삭제 20210119
      this.$router.push("/origin" + path).catch(() => {});
    },

    moveBoardPage(path) {
      sessionStorage.clear(); // 세션스토리지에 저장한 검색유지조건들 제거 20210119 과연됨?
      this.$router.push("/admin" + path);
    },

    logout() {
      this.axios
        .get("/api/v1/login/out")
        .then((res) => {
          console.log("res", res);
          alert("로그아웃 되었습니다.");
          localStorage.removeItem("accessToken");
          localStorage.removeItem("id");
          sessionStorage.clear();
          this.$router.push("/login").catch(() => {});
        })
        .catch((err) => {
          console.log(err);
          localStorage.removeItem("accessToken");
          localStorage.removeItem("id");
          sessionStorage.clear();
          alert("세션이 만료되어 로그인 페이지로 이동합니다.");
          this.$router.push("/login").catch(() => {});
        });
    },
  },
};
</script>

<style lang="scss" scoped>
/*
body, div, ul, li, ol, dd, dt, dd, h1, h2, h3, h4, h5, h6, p, form, fieldset, legend, select, input {margin:0; padding:0;}

body, input, textarea, select, button, table {font-family:'Malgun Gothic', "돋움", Dotum, hevetica, sans-serif; font-size:12px; line-height:1.7;}

h1, h2, h3, h4, h5, h6, {font-size:12px; font-weight:nomal;}

a {color:#666; text-decoration:none;}

a:hover, a:active {color:#6699FF;}

li, ul, ol {list-style:none;}

img { border:0;}

textarea, input {border:1px solid #d0d0d0;}

body {margin:0; padding:0;}

td {font-family:gulim; font-size:9pt; color:#736257;}*/
.point {
  font-family: gulim;
  font-size: 9pt;
  color: #faf7e7;
}

.textbox {
  font-family: gulim;
  font-size: 9pt;
  color: #736257;
  height: 20px;
}

.msg_wrap {
  width: 230px;
}

.msg_deco_wrap {
  border-radius: 13px 13px 0 0;
  background: #efefef;
  padding: 12px 0 0 0;
}

.msg_deco {
  width: 40px;
  height: 2px;
  background: #ababab;
  border-radius: 20px;
  margin: 0 auto;
}

.msg_box {
  background: #efefef;
  padding: 8px 20px 10px 20px;
  display: block;
  height: 200px;
}

.msg_box textarea {
  width: 100%;
  height: 100%;
}

.msg_send {
  background: #efefef;
  padding: 0 20px 20px 20px;
}

.msg_footer {
  background: #efefef;
  border-radius: 0 0 13px 13px;
  padding: 0 20px 20px 20px;
  text-align: center;
}

.hq_top {
  padding: 0px;
}

.hq_top .wrap a img {
  padding-top: 25px;
}

.hov-menu {
  padding-top: 25px;
  padding-bottom: 32px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAOCAIAAAEWJtGdAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MDJFRTgwNjY4MzE1MTFFNzlBQTVGMkQ2QzUzM0M1MkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MDJFRTgwNjc4MzE1MTFFNzlBQTVGMkQ2QzUzM0M1MkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDowMkVFODA2NDgzMTUxMUU3OUFBNUYyRDZDNTMzQzUyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDowMkVFODA2NTgzMTUxMUU3OUFBNUYyRDZDNTMzQzUyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PnNCdPcAAAAgSURBVHjaYnBxcWFiYGAACCAGKA0QQDAahAECDJmNjgGE2ANFCuioZwAAAABJRU5ErkJggg==)
    no-repeat 15px 26px;

  ul {
    display: none;
    float: none;
    background: #fff;
  }

  &:hover {
    div {
      min-width: 1600px;
      box-sizing: border-box;
      position: absolute;
      display: block;
      padding: 0;
      padding-top: 7px;
      width: 100%;
      margin-top: 32px;
      left: 0;
      border-top: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
      background: white;
      z-index: 1;

      ul {
        // position: absolute;
        // display: block;
        padding: 0;
        width: 100%;
        // margin-top: 40px;
        margin-left: 44%;
        width: 60%;
        display: block;
        float: left;
        padding-top: 0;

        li {
          &:first-child {
            border-top: none;
          }

          // border: 1px solid #ddd;
          margin: 0;
          padding: 0;
          // width: 100%;
          padding: 0px 10px;
          white-space: nowrap;
          text-align: center;
          border-right: none;
          border-left: none;
          height: 47px;
          line-height: 40px;
          float: left;
          color: black;
          width: auto;
          margin-left: 30px;
          background: none;

          a {
            font-size: 18px;
            font-weight: 200;
            cursor: pointer;

            strong {
              color: black;
            }
          }
        }
      }
      .submenu-consult {
        margin-left: 39%;
      }
      .submenu-counselor {
        margin-left: 55%;
      }
      .submenu-customer {
        margin-left: 43%;
      }
      .submenu-customer-counselor {
        margin-left: 65%;
      }
      .submenu-teacher {
        margin-left: 48%;
      }
      .submenu-teacher-counselor {
        margin-left: 69%;
      }
      .submenu-center {
        margin-left: 52%;
      }
      .submenu-company {
        margin-left: 56%;
      }
      .submenu-statistics {
        margin-left: 58%;
      }
      .submenu-salary {
        margin-left: 60%;
      }

      .submenu-salary-counselor {
        margin-left: 70%;
      }
    }
  }
}
</style>
